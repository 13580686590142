<template>
  <vx-card title="Upload Supplier Invoice">
    <div class="vx-row mb-6 d-none" style="width: 50%; display:none">
      <div class="vx-col sm:w-1/3 w-full flex items-center d-none">
        <span>Priority</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full d-none">
        <multiselect v-model="selectedPriority" :options="priorities" :searchable="false" :close-on-select="false" :show-labels="false"
                     placeholder="Pick a value"> <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
        </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template></multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Supplier</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (*.pdf)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file_attachment"
          name="file_attachment"
          accept=".pdf"
          required
          multiple
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
        >Submit</vs-button
        >
      </div>
    </div>

      <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
              <vs-table
                      search
                      stripe
                      border
                      description
                      :sst="true"
                      :data="table.data"
                      :max-items="table.length"
                      :total="table.total"
                      @search="handleSearch"
                      @change-page="handleChangePage"
                      @sort="handleSort"
                      v-model="selected"
                      not-click-selected
                      open-expand-only-td
              >
                  <template slot="header">
                      <vs-dropdown vs-trigger-click class="cursor-pointer">
                          <div
                                  class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
                          >
              <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
                              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                          </div>
                          <vs-dropdown-menu>
                              <vs-dropdown-item
                                      v-for="item in table.limits"
                                      :key="item"
                                      @click="handleChangelength(item)"
                              >
                                  <span>{{ item }}</span>
                              </vs-dropdown-item>
                          </vs-dropdown-menu>
                      </vs-dropdown>
                  </template>
                  <template slot="thead">
                      <vs-th sort-key="created_at">File</vs-th>
                      <vs-th sort-key="created_at">Description</vs-th>
                      <vs-th>Upload Date</vs-th>
                      <vs-th>PIC</vs-th>
                      <vs-th>Status</vs-th>
                      <vs-th>Detail</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                      <vs-tr
                              :data="tr"
                              :key="indextr"
                              v-for="(tr, indextr) in data"
                              :class="data[indextr].class"
                      >
                          <vs-td>
                              <a :href="data[indextr].file_url">{{  getFileNameFromURL(data[indextr].file_name) }}</a>
                          </vs-td>
                          <vs-td>
                              Supplier : {{  getValueFromObject(data[indextr].data_supplier,"Code") }}-{{  getValueFromObject(data[indextr].data_supplier,"Name") }}<br/>
                          </vs-td>
                          <vs-td>
                              {{ dateFormatWithTime(data[indextr].created_at) }}
                          </vs-td>
                          <vs-td>
                            {{ getValueFromObject(data[indextr].data_pic,"name")  }}
                          </vs-td>
                          <vs-td>
                               {{  (data[indextr].status) }}<br/>
                           </vs-td>

                        <vs-td>
                          <a
                          ><vs-button
                            class="mt-2 ml-2"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-search"
                            v-on:click="showDetail(indextr)"
                          ></vs-button
                          ></a>
                        </vs-td>
                      </vs-tr>
                  </template>
              </vs-table>
              <vs-pagination
                      style="padding-top: 5px"
                      :total="table.totalPage"
                      v-model="setPage"
              />
          </div>

        <transition name="detail-fade">
          <div
            v-if="detail"
            v-bind:class="[
            detail
              ? ' nonfixed vx-col md:w-1/2 w-full mb-base vertical-divider'
              : '',
            detailHide,
          ]"
          >
            <div>
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                style="margin-bottom: 2%"
              >Close</vs-button
              >
            </div>
            {{ selected.status_note }}
          </div>
        </transition>
      </div>
  </vx-card>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      selectedPriority: '',
      priorities:
      [
        { Name: 'Standart' },
        { Name: 'Urgent' },
        { Name: 'Very Urgent' }
      ],
      uploadReady: true,
      optionSupplier: [],
      supplier: null,
      warehouse: null,
      status_submit: false,
      dn_date: null,
      received_date: null,
      detail: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      selected: {},
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      period: {},
      postingDate: null,
      optionPeriod: [{}],
      disabledDates: {},
    };
  },
  methods: {
    getValueFromObject(objJson, keyVar){
        if (objJson && typeof objJson!== 'undefined' && objJson!==""){
            let jsonData = JSON.parse(objJson)
            return  jsonData[keyVar]
        }
        return "-"
    },
    getFileNameFromURL(url) {
        if (url === ""){
          return "File upload failed."
        }
        const parts = url.split('/');
        const lastPart = parts[parts.length - 1];
        const fileName = lastPart.split('?')[0];
        return decodeURIComponent(fileName);
    },
    dateFormat(date) {
        if (date) {
            if (String(date).includes("-")) {
                return moment(String(date)).format("YYYY-MM-DD");
            }else  if (String(date).includes("/")) {
                const inputDate = String(date);
                const parts = inputDate.split("/");
                if (parts.length === 3) {
                    return  moment(String(`${parts[2]}-${parts[1]}-${parts[0]}`)).format("YYYY-MM-DD") ;
                }else {
                    return ""
                }
            }else {
                const inputDate = String(date);
                if (inputDate.length >= 8) {
                    const day = inputDate.substr(0, 2);
                    const month = inputDate.substr(2, 2);
                    const year = inputDate.substr(4, 4);

                    const formattedDate = `${year}-${month}-${day}`;
                    return  moment(String(formattedDate)).format("YYYY-MM-DD") ;

                } else {
                    return ""
                }
            }
        }
    },
    dateFormatWithTime(date) {
      if (date) {
        if (String(date).includes("-")) {
          return moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
        }else  if (String(date).includes("/")) {
          const inputDate = String(date);
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return  moment(String(`${parts[2]}-${parts[1]}-${parts[0]}`)).format("YYYY-MM-DD hh:mm:ss") ;
          }else {
            return ""
          }
        }else {
          const inputDate = String(date);
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return  moment(String(formattedDate)).format("YYYY-MM-DD hh:mm:ss") ;

          } else {
            return ""
          }
        }
      }
    },
    async handleSubmit() {

      if (!this.selectedPriority) {
        this.selectedPriority = "";
      }

      this.status_submit = true;

      const formData = new FormData();
      if (this.supplier) {
          formData.append("supplier_code", this.supplier.ID);
      }
      formData.append("priority", this.selectedPriority.Name);

      const files = this.$refs.file_attachment.files;
      if (files.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File  is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        this.$vs.loading.close();
        return
      }
      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files[i]);
      }

      this.$vs.loading();
      try {
           await this.$http
              .post('/api/v1/ocr/uploader',formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  },
              })
              .then((resp) => {
                  if (resp.success ) {
                      var message = "";
                      this.$vs.notify({
                          color: "success",
                          title: "Upload Success",
                          text: message,
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-x-circle",
                      });

                    this.$vs.loading.close();
                  } else {
                      this.$vs.notify({
                          color: "danger",
                          title: "Error",
                          text: resp.message,
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-x-circle",
                      });

                    this.$vs.loading.close();
                  }
                  this.$vs.loading.close();

                  this.selectedPriority = null;
                  this.supplier = null;
                  this.files = [];
                  this.status_submit = false;
                  this.$refs.file_attachment.value = '';

              })
              .catch((error) => {
                  this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: error,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                  });
                  this.$vs.loading.close();
              });
      } catch (error) {
        console.error('Error uploading files:', error);
        this.$vs.loading.close();

      }
      this.getData()

    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    closeDetail() {
      this.detail = false;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    showDetail(index) {
      this.selected = this.table.data[index];
      this.detail = true;
    },
    getData() {
        this.selectAll = false;
        this.$vs.loading();
        this.$http
            .get("/api/v1/ocr/data", {
                params: {
                    limit: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    warehouse_code: this.warehouse_code,
                    from_date: this.from_inv_date,
                    to_date: this.to_inv_date,
                },
            })
            .then((resp) => {
                if (resp.code == "200") {
                    this.table.total = resp.data.record_total;
                    this.table.totalPage = resp.data.page_total;
                    this.table.totalSearch = resp.data.record_total_search;
                    this.table.length = resp.data.record_total_per_page;
                    this.table.data = resp.data.records;
                    this.data = resp.data.records;
                    if (this.data !=null) {
                        for (let i = 0; i < this.data.length; i++) {

                        }
                    }
                }
                this.setStartEnd();
                this.$vs.loading.close();
            });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

  },
  mounted() {
      this.getOptionSupplier();
      this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {

  },
};
</script>
<style scoped>
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.highlight > td {
  background-color: #d0cdf0;
}


.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
</style>
