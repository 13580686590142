<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class=" vx-row w-full  ml-auto mr-auto">

    <div class="vx-col w-1/2">
        <span>
        <h4 class="mb-5">Supplier Invoice OCR</h4>
      </span>
      <div class="row bg bg-light" style="padding-bottom: 60px;">
        <div class="row mb-2">
          <multiselect
            v-model="selectedPages"
            :options="pageOptions"
            key="pageOCR"
            placeholder="Select pages"
            label="Page"
            track-by="page"
            :multiple="false"
            :taggable="false"
            :close-on-select="true"
            :clear-on-select="false"
            :max="15"
            re
            :show-labels="false"
            :custom-label="customLabelPage"
            @input="handleSelectionChangePage"
          ></multiselect>
        </div>
        <div class="col-md-10 offset-md-1">
          <div class="row" style="margin: 0px 0">
            <div class="col-md-6" style="overflow: hidden;height: 600px;" id="vcc">
              <vue-cutter
                ref="cutter"
                :container-bounding="optionCutter.bounding"
                :crop-box-bounding="optionCutter.cropB"
                :crop-dividing-line="true"
                :src="optionCutter.src"
                :crop-info="true"
                mode="cover"
                @preview="preview"
                @imgLoad="imgLoad"
              ></vue-cutter>
            </div>

              <div class="col-md-3 w-full d-none" v-if="false">
                <div class="vx-col w-[80%] d-none">
                  <div class="showBox p-2 d-none">
                    <vs-button class="float-right ml-2 d-none" @click.stop="getOcr"> OCR</vs-button>
                    <div class="imgCutDownBoxContainer " v-html="preHtml"></div>
                  </div>
                </div>
                <div class="vx-col w-[20%]">
                </div>
              </div>
          </div>

        </div>
      </div>

    </div>
    <div class="vx-col w-1/2">
    <span>
      <h4 class="mb-5"></h4>
    </span>
      <div class="vx-row mb-2">
        <vs-divider style="width: 100%; margin-left: 2%">Supplier Data</vs-divider>

        <div class="vx-col w-1/2" v-if="optionSupplier">
          <span>Supplier</span>
          <multiselect
              class="selectExample input-readonly"
              key="supplier_id"
              v-model="dataValid.supplier_id"
              :options="optionSupplier"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="4"
              track-by="ID"
              label="Name"
              :disabled="false"
              readonly
          >
            <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.Name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly   "
            label="Name"
            name="pengusaha_nama"
            @click.stop="setFocusedInput('pengusaha_nama')"
            v-model="dataValid.pengusaha_nama"
            readonly
          />


        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-1/2">


          <vs-input
            class="w-full input-readonly"
            label="PIC"
            name="pic_name"
            @click.stop="setFocusedInput('pic_name')"
            v-model="dataValid.pic_name"
            readonly
          />
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="PIC Phone Number"
            name="pic_phone"
            @click.stop="setFocusedInput('pic_phone')"
            v-model="dataValid.pic_phone"
            readonly
          />
        </div>

      </div>
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Bank Name"
            name="bank_name"
            @click.stop="setFocusedInput('bank_name')"
            v-model="dataValid.bank_name"
            readonly
          />
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Bank Account"
            name="bank_account"
            @click.stop="setFocusedInput('bank_account')"
            v-model="dataValid.bank_account"
            readonly
          />
        </div>
      </div>
      <div class="vx-row ">
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly"
            label="Tax Number"
            name="nomor_seri"
            @click.stop="setFocusedInput('nomor_seri')"
            v-model="dataValid.nomor_seri"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly"
            label="Tax Date"
            name="tax_date"
            @click.stop="setFocusedInput('tax_date')"
            v-model="dataValid.tax_date"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly "
            label="Tax Value"
            name="tax_value"
            @click.stop="setFocusedInput('tax')"
            v-model="dataValid.tax"
            readonly
          />
        </div>
      </div>


      <vs-divider style="width: 100%; margin-left: 2%">PO Data</vs-divider>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Code"
            name="po_code"
            v-model="dataValid.po"
            @click.stop="setFocusedInput('po')"
            placeholder="Invoice Number"
            readonly
          />
        </div>
        <div class="vx-col w-1/2 mb-2">
          <vs-input
            class="w-full input-readonly "
            label="PO Date"
            name="po_date"
            @click.stop="setFocusedInput('po_date')"
            v-model="dataValid.po_date"
            readonly
          />
        </div>
      </div>


      <vs-divider style="width: 100%; margin-left: 2%">Invoice Data</vs-divider>
      <div class="vx-row  ">
        <div class="vx-col w-full">
          <span>Invoice Type</span>
            <multiselect v-model="dataValid.invoice_type"
                         readonly
                       key="invoice_type" :options="optionsInvoiceType"
                       placeholder="Select Invoice Type"></multiselect>
          <span>Invoice Group</span>
          <multiselect v-model="dataValid.invoice_group"
                       readonly
                       key="invoice_group" :options="optionsIvoiceGroup"
                       placeholder="Select Invoice Group"></multiselect>
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly"
            label="Invoice Number"
            name="invoice_number"
            @click.stop="setFocusedInput('no')"
            v-model="dataValid.no"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly "
            label="External Code"
            name="external_code"
            @click.stop="setFocusedInput('external_code')"
            v-model="dataValid.external_code"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly "
            label="Barcode"
            name="barcode"
            v-model="dataValid.barcode"
            @click.stop="setFocusedInput('barcode')"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly  "
            label="Invoice Date"
            name="invoice_date"
            v-model="dataValid.dndate"
            @click.stop="setFocusedInput('dndate')"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly "
            label="Create Date"
            name="create date"
            v-model="dataValid.createdDate"
            @click.stop="setFocusedInput('createdDate')"
            readonly
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full input-readonly "
            label="Invoice Due Date"
            name="due_date"
            v-model="dataValid.duedate"
            @click.stop="setFocusedInput('duedate')"
            readonly
          />
        </div>

      </div>

      <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <vs-textarea readonly="readonly"
        v-model="dataValid.note"
      />
    </div>
    <div class="vc-col w-full">
      <vs-divider style="width: 100%; margin-left: 2%"
      >List Item Invoice
      </vs-divider
      >
      <div>
        <vs-table>
          <vs-tr>
            <vs-th>Item Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>UOM</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Unit Price</vs-th>
            <vs-th>Sub Total</vs-th>
            <vs-th>Discount</vs-th>
            <vs-th>Total</vs-th>
          </vs-tr>
          <vs-tr v-for="(item, index) in dataValid.product_item" :key="index">
            <vs-td>
              <vs-input class="input-readonly" v-model="item.product_id" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input class="input-readonly"  v-model="item.item_name" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input class="input-readonly" v-model.number="item.item_unit" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input class="input-readonly" v-model.number="item.quantity" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input class="input-readonly" v-model.number="item.price" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>{{ item.quantity * item.price }}</vs-td>
            <vs-td>
              <vs-input class="input-readonly" v-model.number="item.discount" readonly="readonly" type="text"></vs-input>
            </vs-td>
            <vs-td>
              <vs-input class="input-readonly" v-model.number="item.price_discount" readonly="readonly" type="text"></vs-input>
            </vs-td>
          </vs-tr>
        </vs-table>
      </div>
    </div>
    <div class="vx-row mb-2 mt-6" v-if="false">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click.stop="handleUpdate">Update
        </vs-button>
      </div>
    </div>
  </div>

</template>
<script>
import Datepicker from "vuejs-datepicker";
import {VueCutter} from 'vue-cutter'
import Territory from "@/views/oms/master/sales-area/Territory.vue";
import {Int} from "vuecode.js";

export default {
  components: {
    Territory,
    Datepicker,
    VueCutter,
  },
  props: {
      optionSupplier:{
          type :Object,
      },
    dataItem: {
      type: Object,
    },
    fileName: {
      type: String,
    },
    dataJSON: {
      type: Object
    },
    page: {
      type: Int,
    },
    pageCount: {
      type: Int,
    },
    dataValid: {
      type: Object
    },
    dataInvoice: {
      type: String
    },
    dataGR: {
      type: String
    },
    dataPO: {
      type: String
    },
    dataSupplier: {
      type: String
    },
    piId: {
      type: Number,
    },
    pic_name: {
      type: String,
    },
    pic_phone: {
      type: String,
    },
    tax_date: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    po_date: {
      type: String,
    },
    warehouse: {
      type: String,
    },
    gr_code: {
      type: String,
    },
    gr_date: {
      type: String,
    },
    external_code: {
      type: String,
    },
    barcode: {
      type: String,
    },
    payment_term: {
      type: String,
    },
    new_notes: {
      type: String,
    },
    ocr_value: {
      type: String,
    },
    ocr_var: {
      type: String,
    },
  },
  created() {
    this.selectedPages = [this.pageOptions[0]];

  },
  data() {
    return this.initialState();
  },
  methods: {
    setFocusedInput(varName){
       console.log(this.dataValid[varName]);
       this.ocr_var = varName;
    },
    getOcr() {
      if (!this.ocr_var) {
        return
      }
      let varData =this.ocr_var
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextOcr(image64[1], varData)
      })
    },
    handleSelectionChangePage(newValues) {
      this.getPdfS3Image(newValues.page);
    },
    customLabelPage(option) {
      return `Page ${option.page}`;
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            let allSupplier = {
              "ID": 0,
              "Code": "",
              "ShortName": "",
              "Name": "ALL SUPPLIER",
              "Address": "",
              "City": "",
              "ContactName": "",
              "ContactMobile": "",
              "Phone": "",
              "PaymentTermID": 0,
            }

            this.optionSupplier = [allSupplier, ...resp.data.records];
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    initialState() {
      return {
        optionSupplier: null,
        selectedInvoiceTypeValue: null,
        selectedInvoiceGroupValue: null,
        optionsInvoiceType: [
          'invoice',
          'advance'
        ],
        optionsIvoiceGroup: [
          'trade',
          'non trande'
        ],
        selectedPages: [],
        pageOptions: Array.from({length: this.pageCount}, (_, index) => ({page: index + 1})),
        optionCutter: {
          bounding: '800px 1024px',
          src: "",
          cropB: '0%',
          imageload: ''
        },
        imageload: false,
        preHtml: '',
        remotePic: '',
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        notes: "",
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          discount_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        net: [],
        total: [],
        gr_codes: "",
        due_date: null,
        tr_number: null,
        tr_date: null,
      };
    },
    preview(data) {
      this.preHtml = data.html
    },
    imgLoad(params) {
      this.optionCutter.imageload = params
    },
    paramData() {
      return {
        pi_id: this.piId,
        data_valid: this.dataValid,
        notes: this.new_notes,
        status: "draft",
      };
    },
    postData() {
      this.$http
        .put(`/api/v1/ocr/invoice/${this.piId}`, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id', this.piId)
      form.append('file', this.file)
      return form
    },
    paramImageS3(currPage) {
      var form = new FormData()
      form.append('pdf_name', this.fileName)
      form.append('page', currPage)
      return form
    },
    paramImage64(image64, text_type) {
      var form = new FormData()
      form.append('image64', image64)
      form.append('text_type', text_type)
      return form
    },
    getTextOcr(image64,varData) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
        .post("/api/v1/ocr/image64", this.paramImage64(image64, "TEXT"), null)
        .then((resp) => {
          this.ocr_value = resp.data;
          this.dataValid[varData] = this.ocr_value;
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getPdfS3Image(pages) {
      if (this.fileName === null || pages === null) {
        return
      }
      this.imageload = false;
      this.$vs.loading()
      this.$http
        .post("/api/v1/ocr/pdf-s3/image64", this.paramImageS3(pages), null)
        .then((resp) => {
          this.$vs.loading.close()
          this.imageload = true;
          this.optionCutter.src = `data:image/png;base64,${resp}`
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleUpdate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
          console.log(this.paramData());
        }
      });
    },

  },
  mounted() {

    setTimeout(() => {

      this.getOptionSupplier();
      if (this.piId && this.fileName) {
        this.getPdfS3Image(1)
        this.table.inv_code = this.invCode != 0 ? this.invCode : null;
        this.$nextTick(() => {
          this.due_date = this.dueDate2
        });
      }
    }, 2000)

  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getPdfS3Image(1)
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
      this.$nextTick(() => {
        this.due_date = this.dueDate2
      });
    },
  },
};
</script>

<style scoped>
.main {
  text-align: center;
  background: linear-gradient(60deg, #543ab7, #00acc1) !important;
  border-radius: 0;
  padding: 4rem 0 10rem 0;
  color: #fff;
}

.c img {
  margin: 0.3rem 0.5rem;
}

.cc button {
  margin: 1rem 0.5rem;
}

.wb {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 50px;
  background: linear-gradient(to bottom, #543ab7, #fff);
}

.showBox {
  width: 100%;
  background: #dedede;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 5px auto;
  min-height: 50px;
}

.imgCutDownBoxContainer {
  border: 1px solid #ccc;
  background: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;

}

.vc-controller {
  width: 100%;
  height: 25px;
  background: rgba(255, 255, 255, 0.07);
  position: absolute;
  bottom: 0;
}

.scale {
  font-size: 11px;
  padding: 1px 6px;
  background: #79a7d1;
  position: relative;
  bottom: 2px;
  border-radius: 3px;
  color: white;
}

.controller-icon-scale {
  padding: 0 8px;
  cursor: pointer;
  color: #c5cbd1;
}

.controller-icon-turn {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.icon {
  opacity: 0.5;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
}

.vc-controller:hover {
  background: rgba(255, 255, 255, 0.2);
}

.controller-icon {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
