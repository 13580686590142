<template>
  <div class="vx-row mb-12">

    <div v-if="!detail"
         v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        v-model="selected"
        not-click-selected
        open-expand-only-td
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="0%"></vs-th>
          <vs-th sort-key="created_at">Scan Date</vs-th>
          <vs-th>Invoice Date</vs-th>
          <vs-th>Invoice Type</vs-th>
          <vs-th sort-key="due_date">Invoice Data</vs-th>
          <vs-th sort-key="barcode">Barcode</vs-th>
          <vs-th>Tax Data</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            {{
              data[indextr].product_item
            }}
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Show Invoice">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(
                        data[indextr].id,
                        indextr,
                        data[indextr].data_valid,
                        data[indextr].dataJson,
                        data[indextr].file_name,
                        data[indextr].pages,
                        data[indextr].data_valid['product_item']
                      )
                    "
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>
              {{ dateFormat(data[indextr].created_at) }}
            </vs-td>
            <vs-td>
              {{ data[indextr].data_valid["dndate"] }}
            </vs-td>
            <vs-td>
              {{ data[indextr].data_valid["invoice_type"] }}
            </vs-td>
            <vs-td>
              No : {{ data[indextr].data_valid["no"] }}<br/>
              D/N : {{ data[indextr].data_valid["dn"] }}<br/>
              D/N Date : {{ data[indextr].data_valid["dndate"] }}<br/>
              PO :{{ data[indextr].data_valid["po"] }}<br/>
              Ship To :{{ data[indextr].data_valid["shipto"] }}<br/>
              Due Date :{{ data[indextr].data_valid["duedate"] }}<br/>
            </vs-td>
            <vs-td>
              {{  (data[indextr]["barcode"]) }}
            </vs-td>
            <vs-td>
              Subtotal :  {{ numberFormat(data[indextr].data_valid["dpp"]) }}<br/>
              Tax : {{ numberFormat(data[indextr].data_valid["tax"]) }}<br/>
              Invoice Balance : {{ numberFormat(data[indextr].data_valid["gross"]) }}<br/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <div class="vx-row mb-2 mt-6 d-none" v-if=" selected &&  selected.length > 0">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click.stop="handleRelease">Reject
        </vs-button>
      </div>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            >Close
            </vs-button
            >
          </vs-col>

          <detail
            @close="handleClose"
            :piId="piId"
            :data-j-s-o-n="dataJson"
            :page-count="pageCount"
            :data-valid="dataValid"
            :file-name="fileName"
            :data-item="dataItem"
            :option-supplier="optionSupplier"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
    <div>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doReject"
        @close="closeReject"
        :active.sync="activePromptReject"
      >
        <div class="con-exemple-prompt">
          Are you sure Reject invoice  ?
        </div>
      </vs-prompt>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doDelete"
        @close="closeDelete"
        :active.sync="activePromptDelete"
      >
        <div class="vx-row mb-2">
        </div>
        <div class="con-exemple-prompt">
          Are you sure to Delete Draft invoice : {{ piId }} ?
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Detail from "./detail.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Detail,
    Datepicker,
  },
  props: {
      optionSupplier:{
          type: Object
      },
    warehouse_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
  },
  data() {
    return {
      selected: [],
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      piId: null,
      fileName: null,
      dataItem: null,
      dataJson: null,
      pageCount: 0,
      dataValid: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      warehouseCode: null,
      warehouseName: null,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "created_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePromptReject : false,
      activePromptDelete : false,
      activePrompt: false,
      activePromptReversal: false,
      createdDate: null,
      dueDate: null,
      invDate: null,
      trNumber: null,
      trDate: null,
      reversal_date: new Date(),
      disabledAfterToday: {
        from: new Date(), // Disable all dates after specific date
      },

      selectedItems: [],
      selectAll: false,
      isAllSelected: false,
      checked: [],
      selectable: true, // Enable row selection
    };
  },
  methods: {
    handleRelease(){
      if (this.selected && this.selected.length > 0) {
        this.activePromptReject = true
      }
    },
    doReject(){
      const ids = this.selected.map(select => select.id);
      const dataValid = this.selected.map(select => select.data_valid);
      const dataPO = this.selected.map(select => select.data_po);
      let param = {
        id: ids,
        piID: dataPO,
        status: "reject",
        data_valid: dataValid,
      };
      this.$vs.loading();
      this.$http
          .put(`/api/v1/ocr/invoices`,param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            this.getData();
          })
          .catch(() => {
            this.$vs.loading.close();
          });

    },
    closeReject(){
      this.activePromptReject = false
    },
    doDelete(){
      this.$vs.loading();
      this.$http
          .delete(`/api/v1/ocr/invoice/${this.piId}`,null)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    closeDelete(){
      this.piId = null;
      this.activePromptDelete = false;
    },
    deleteDraft(id){
      this.piId = id;
      this.activePromptDelete = true;
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode = this.checkedCode.filter(v => {
          return v.ID != val.ID
        })
        // this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val);
      }
    },
    toggleAll() {
      this.isAllSelected = !this.isAllSelected;
      console.log(this.isAllSelected);
    },
    selectAllToggle() {
      // this.selectedItems = this.tableData.map((row) => row.id); // Select all or none
      this.selectAll = !this.selectAll; // Toggle selectAll state
    },
    someActionSelected() {
      // Perform an action with the selected items
      console.log('Selected items:', this.selectedItems);
    },
    dateFormat(date) {
      if (date) {
        if (String(date).includes("-")) {
          return moment(String(date)).format("YYYY-MM-DD");
        }else  if (String(date).includes("/")) {
          const inputDate = String(date);
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return  moment(String(`${parts[2]}-${parts[1]}-${parts[0]}`)).format("YYYY-MM-DD") ;
          }else {
            return ""
          }
        }else {
          const inputDate = String(date);
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return  moment(String(formattedDate)).format("YYYY-MM-DD") ;

          } else {
            return ""
          }
        }
      }
    },
    moneyFormat(money) {
      return money
      // if (money===null || typeof money !== 'undefined'){
      //   return money
      // } return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    numberFormat(number){
      if (number==null || typeof number ==="undefined") {
        return "0"
      }
      var strNumber = parseFloat(number.toString().replace(",", ".")).toFixed(2);
      var formatter = new Intl.NumberFormat('id-ID');
      return  formatter.format(strNumber);

    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      console.log(active, "active")
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    objectToKeyValueArray(obj) {
      var keyValueArray = [];

      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            var nestedKeyValueArray = this.objectToKeyValueArray(obj[key]);
            for (var i = 0; i < nestedKeyValueArray.length; i++) {
              var nestedKey = nestedKeyValueArray[i][0];
              var nestedValue = nestedKeyValueArray[i][1];
              keyValueArray.push([key + '.' + nestedKey, nestedValue]);
            }
          } else {
            keyValueArray.push([key, obj[key]]);
          }
        }
      }

      return keyValueArray;
    },
    shouldRemove(word) {
      let wordsToRemove = ["barang", "pajak", "harga", "jasa"];
      return wordsToRemove.includes(word);
    },
    findValueByPartialKey(arr, partialKey) {
      let filteredKeyValueArray = arr.filter(item => item[0].includes(partialKey));
      let arrs = filteredKeyValueArray.map(item => item[1]);
      return arrs.filter(item => item !== "" && !this.shouldRemove(item));
    },
    cleanString(valueArray, indexUse) {

      if (valueArray === null || valueArray.length === 0 || indexUse >= valueArray.length) {
        return "";
      }

      let value = valueArray[indexUse];
      return  value;
      /*if (value.trim() !== "") {
        return value.trim();
      } else {
        return "";
      }*/
    },
    removeNonAlphabeticCharacters(inputString) {
      // Use regular expression to replace non-alphabetic characters with an empty string
      return inputString.replace(/[^a-zA-Z]/g, '');
    },
      removeNonNumericCharacters(inputString) {
      // Use regular expression to replace non-numeric characters with an empty string
      return inputString.replace(/\D/g, '');
    },
      containsInteger(inputString) {
      return /\d/.test(inputString);
    },
    getData() {
      this.selectAll = false;
      this.$vs.loading();
      this.$http
          .get("/api/v1/ocr/data", {
            params: {
              limit: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              warehouse_code: this.warehouse_code,
              from_date: this.from_inv_date,
              to_date: this.to_inv_date,
              status: "reject",
            },
          })
          .then((resp) => {
            if (resp.code == "200") {
              this.table.total = resp.data.record_total;
              this.table.totalPage = resp.data.page_total;
              this.table.totalSearch = resp.data.record_total_search;
              this.table.length = resp.data.record_total_per_page;
              this.table.data = resp.data.records;
              this.data = resp.data.records;
              if (this.data !=null) {

                for (let i = 0; i < this.data.length; i++) {
                  let dataJson = {}
                  dataJson =  JSON.parse(this.data[i]["data_json"].toLowerCase())

                  this.data[i]["json_data"] = dataJson
                  let objArray = this.objectToKeyValueArray(dataJson)
                  if (this.data[i]["data_valid"].length <10) {
                    let dataPOArray =  this.cleanString(this.findValueByPartialKey(objArray, 'header-1.po'), 0);
                    let dataPO =dataPOArray.split(" ");
                    let allArayItem = [];
                    let allProductItem = [];
                    let isInvoice = false;
                    if (dataJson !==null) {
                      for (let dataJsonElement of dataJson) {
                        let arrayItem = []
                        if (dataJsonElement["type"].toLowerCase()==="invoice")
                        {
                          arrayItem = dataJsonElement["data"] ? dataJsonElement["data"]["items"] : [];
                          allArayItem = allArayItem.concat(arrayItem);
                          isInvoice = true
                        }
                      }
                    }

                    // eslint-disable-next-line no-constant-condition
                    if (isInvoice && allArayItem.length!==0 && false ){
                      for (const allArayItemElement of allArayItem) {
                        if (allArayItemElement==null) {
                          continue
                        }


                        let inputString = allArayItemElement["item"];
                        if (typeof inputString === 'string') {


                          const parts = inputString.split(' ');
                          const productId = parts.shift();
                          const namaProduk = parts.slice(0, parts.findIndex(part => part === 'discount')).join(' ');
                          const arrayNamaProduk = namaProduk.toString().split(" ");


                          const amount = arrayNamaProduk[arrayNamaProduk.length-1];
                          const price = arrayNamaProduk[arrayNamaProduk.length-2];
                          let qtyStr = arrayNamaProduk[arrayNamaProduk.length-3];
                          let unitStr = arrayNamaProduk[arrayNamaProduk.length-4];
                          let endIndex = arrayNamaProduk.length-4;
                          if (this.containsInteger(qtyStr)){
                            unitStr = this.removeNonNumericCharacters(qtyStr);
                            qtyStr = this.removeNonAlphabeticCharacters(qtyStr);
                            endIndex = arrayNamaProduk.length-3;
                          }

                          let fixName =  arrayNamaProduk.slice(0, endIndex);
                          let namaProdukFix = fixName.join(" ")

                          const discount = parts[parts.findIndex(part => part === 'discount') + 1].replaceAll("%","");
                          const priceDiscount = parts[parts.length-1].replaceAll("(","").replaceAll(")","");

                          let itemProduct ={
                            'product_id': productId,
                            'item_name': namaProdukFix,
                            'quantity': unitStr,
                            'item_unit': qtyStr,
                            'price': price,
                            'tax': 0,
                            'amount': amount,
                            'discount': discount,
                            'price_discount': priceDiscount,
                          }
                          allProductItem = allProductItem.concat(itemProduct);
                        } else {
                          // console.log('Input is not a string : ',inputString);
                        }
                      }
                    }
                    this.data[i]["data_valid"] = {
                      invoice_balance: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.invoice_balance'), 0),
                      invoice_type: "invoice",
                      invoice_group: true,
                      invoice_number: "",
                      supplier_id: null,
                      sub_total: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.sub_total'), 0),
                      tax: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.tax'), 0),
                      dn: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.dn'), 0),
                      dndate: this.dateFormat( this.cleanString(this.findValueByPartialKey(objArray, 'header-1.dndate'), 0)),
                      duedate: this.dateFormat(this.cleanString(this.findValueByPartialKey(objArray, 'header-1.duedate'), 0)),
                      no: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.no'), 0),
                      shipto: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.shipto'), 0),
                      po: dataPO[0] ,
                      nomor_seri: this.cleanString(this.findValueByPartialKey(objArray, 'header.kode.nomor_seri'), 0),
                      pembeli_alamat: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.alamat'), 0),
                      pembeli_nama: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.nama'), 0),
                      pembeli_npwp: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.npwp'), 0),
                      pengusaha_alamat: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.alamat'), 0),
                      pengusaha_nama: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.nama'), 0),
                      pengusaha_npwp: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.npwp'), 0),
                      dph: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.dph'), 0),
                      dpp: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.dpp'), 0),
                      penggantian: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.penggantian'), 0),
                      gross: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.gross'), 0),
                      product_item : allProductItem,
                      pic_name : "",
                      pic_phone: "",
                      tax_date: "",
                      po_date: "",
                      external_code: "",
                      barcode: "",
                      createdDate: "",
                      note: "",
                    };
                  }else{
                    try{
                      this.data[i]["data_valid"] = JSON.parse(this.data[i]["data_valid"]);
                    }catch {
                      this.data[i]["data_valid"]= {};
                    }
                  }
                  try {
                    this.data[i]["data_valid"]["pic"] = JSON.parse(this.data[i]["data_pic"]);
                  }catch {
                    this.data[i]["data_valid"]["pic"] = {};
                  }
                }
              }
            }
            this.setStartEnd();
            this.$vs.loading.close();
          });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleEdit(
      id,
      index,
      dataValid,
      dataJson,
      fileName,
      pageCount,
      dataItem
    ) {
      this.piId = id;
      this.table.data = [];
      this.table.data = this.data;
      this.dataValid = dataValid;
      this.dataJson = dataJson;
      this.pageCount = pageCount;
      this.fileName = fileName;
      this.dataItem = dataItem;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
  },
  mounted() {
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>
